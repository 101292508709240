@import "../../app/colors.scss";
@import "../../app/variables.scss";

.container {
	@media screen and (min-width: $desktop-breakpoint) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		background-color: $color-ab-muted-3;
		padding: 0 3rem 3rem;
		margin: 1rem;
		position: relative;
	}

	.title {
		display: none;
		@media screen and (min-width: $desktop-breakpoint) {
			display: block;
			font-size: 1.8rem;
			> span {
				font-weight: normal;
			}
		}
	}

	.expandButton {
		display: none;
		@media screen and (min-width: $desktop-breakpoint) {
			display: block;
			position: absolute;
			width: 2rem;
			height: 2rem;
			right: 3rem;
			top: 2rem;
			background-color: $color-light-blue;
			&::before {
				color: $color-white;
				font-family: "icomoon", sans-serif;
				display: block;
				content: "\2014";
				position: absolute;
				left: 0.2rem;
				bottom: 10%;
			}
		}
	}

	.collapse::before {
		display: none;
		@media screen and (min-width: $desktop-breakpoint) {	
			color: $color-white;
			font-family: "icomoon", sans-serif;
			display: block;
			content: "\E900";
			position: absolute;
			left: 0.2rem;
			bottom: -3%;
		}
	}

	.description {
		display: none;
		@media screen and (min-width: $desktop-breakpoint) {
			display: block;
			font-size: 1.6rem;
			margin-bottom: 1.8rem;
		}
	}

	.downloadButton {
		box-sizing: border-box;
		width: 100%;
		background-color: $color-white;
		border: 1px solid $color-cta-block-and-keyline;
		@media screen and (min-width: $desktop-breakpoint) {
			max-width: 32rem;
		}
		&::after {
			font-family: "icomoon", sans-serif;
			content: '\E910';
			margin-left: 2rem;
			color: $color-light-blue;
		}
	}

	.closeButton {
		display: block;
		margin: 1rem 0;
		@media screen and (min-width: $desktop-breakpoint) {
			display: none;
		}
	}
}
