.breadcrumbsContainer {
	width: 100%;
	padding: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;

	& > span {
		font-size: 1.4rem;
		font-weight: 300;
		cursor: default;
		flex-basis: auto;
		margin: 0 1rem;

		&.selected {
			font-weight: bold;
			color: #911d8b;
			border-bottom: 2px solid #911d8b;
		}
	}

	& > div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 1rem;

		& .arrow {
			display: inline-block;
			width: 1rem;
			height: 1rem;
			border-top: 1px solid rgb(0, 0, 0);
			border-right: 1px solid rgb(0, 0, 0);
			transform: rotate(45deg);
		}
	}
}
