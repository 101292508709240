@import "../../app/variables.scss";
@import "../../app/colors.scss";

.content {
	min-height: 100%;
	background: $color-white;
	width: auto;
	margin: auto;
	overflow: hidden;
}

.overlay {
	position: fixed;
	top: 0;
	z-index: 10001;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.6);
	overflow-y: auto;
	@media screen and (min-width: $desktop-breakpoint) {
		padding: 4rem 0;
	}
}
