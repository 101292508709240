@import "../../app/colors.scss";
@import "../../app/variables.scss";

.overlayRowContainer {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	padding: 1rem;
	position: absolute;
	z-index: 10000;
	background: $color-white;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	.zoneTitle {
		@media screen and (min-width: $desktop-breakpoint) {
			display: none;
		}
	}
	.mapContainer {
		height: 100%;

		flex-grow: 1;
		@media screen and (max-width: calc(#{$desktop-breakpoint} - 1px)) {
			height: 80%;
		}
		& > div {
			height: 100%;
			position: relative;
		}
	}
	> div {
		button {
			width: 100%;
		}
		&:nth-child(1) {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			@media screen and (min-width: $desktop-breakpoint) {
				display: none;
			}
		}
		&:nth-last-child(1) {
			margin-top: 1rem;
		}
	}
}
