.loadingContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	background: #f6f6f6;
}
.map {
	height: 100%;
	z-index: 1;
	.selectedLabel {
		position: absolute;
		background: #525252;
		border: 1px solid #eee;
		color: #eee;
		padding: 4px 8px;
		border-radius: 16px;
		font-size: 1rem;
		font-family: sans-serif;
	}
}
