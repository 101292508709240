@import '../../app/colors';
@import '../../app/variables.scss';

@mixin arrowExpand {
    font-family: 'icomoon', sans-serif;
    content: '\e91f';
    font-size: 1.4rem;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.serviceAlerts {
    background: $color-warning-muted;
    max-height: 100vh;
    overflow: auto;
    position: fixed;
    min-height: 4.5rem;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 101;
    .title {
        font-weight: bold;
        font-size: 1.4rem;
        display: flex;
        align-items: center;

        &:before {
            content: '\e902';
            color: $color-warning;
            font-family: 'icomoon', sans-serif;
            margin-right: 1.3rem;
            font-size: 1.6rem;
        }
    }

    .controls {
        padding: 1rem 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .arrowOpen {
        width: 2.5rem;
        cursor: pointer;
        transform: rotate(180deg);
        justify-self: center;
        user-select: none;

        &.active {
            transform: rotate(0);
        }
    }

    .alertCounter {
        justify-self: center;
        background: $color-warning;
        border-radius: 100%;
        width: 2.5rem;
        height: 2.5rem;
        color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    & + header {
        top: 4.5rem;
    }

    &.singleAlert {
        height: 7.5rem;
        overflow-y: auto;

        & .controls {
            padding: 0;
            & > div {
                padding: 1rem 2rem;
                background: $color-warning-muted;
            }
        }
    }

    &.singleAlert + header {
        top: 7.5rem;
    }
}

.alertItem {
    border-bottom: 1px solid rgba(255, 138, 1, 0.6);
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;

    .expandIcon {
        transform: rotate(90deg);
        justify-self: center;
        align-self: start;
        cursor: pointer;

        &.active {
            transform: rotate(-90deg);
        }

        &::after {
            @include arrowExpand;
            color: $color-body-copy;
        }
    }

    & > div:last-child {
        padding: 0 3rem;
    }
}
