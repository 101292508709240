@import "../../app/colors.scss";
@import "../../app/variables.scss";

.passengerTypeContainer {
	width: 100%;
	padding: 1rem 0;

	&.noPadding {
		padding: 0;
	}

	.passengerTypeItem {
		margin-top: 1rem;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
		color: rgb(39, 38, 44);
		font-size: 1.6rem;
		font-weight: 300;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		& > .collapsedContainer {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			background-color: $color-white;
			border-left: 3px solid;

			& .titleContainer {
				padding: 1rem 2rem;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				& > span {
					color: rgb(39, 38, 44);
					font-size: 1.6rem;
					min-width: 10rem;
					max-width: 30rem;
					text-overflow: ellipsis;
				}
			}

			.rect {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 5.6rem;
				width: 5.6rem;
				min-width: 5.6rem;
				background-color: $color-light-blue;
				color: $color-white;

				&:before {
					font-family: "icomoon", sans-serif;
					content: "\E900";
					font-size: 3rem;
				}
				&.expandedPlus:before {
					margin-bottom: 0.7rem;
					font-size: 2rem;
					content: "\2014";
				}
			}
		}

		& > .details {
			padding: 1rem;
			color: rgb(85, 89, 94);
			font-size: 1.4rem;
		}
	}

	.nextButtonContainer {
		margin: 2rem;
		& > button {
			width: 100%;
		}
	}
}

@media screen and (min-width: $desktop-breakpoint) {
	.passengerTypeContainer {
		background-color: $color-white;
		height: 100%;
		padding: 0 0 1rem;

		& > :last-child {
			padding: 1rem 3rem;
		}

		.listContainer {
			padding: 1rem 3rem;
		}
	}
}
