@keyframes animate-basket {
	0% {
		transform: translateY(0) rotateZ(0deg);
	}
	20% {
		transform: translateY(-0.6rem) rotateZ(0deg);
	}
	40% {
		transform: translateY(0rem) rotateZ(10deg);
	}
	60% {
		transform: translateY(-0.4rem) rotateZ(-10deg);
	}
	100% {
		transform: translateY(0) rotateZ(0deg);
	}
}
