@import "../../app/colors.scss";

.container {
	width: 100%;

	&.restrainWidth {
		max-width: 21.3rem;
		padding-right: 4rem;

		&.active {
			max-width: 43.8rem;
		}

		.dropdownIndicatorContainer {
			&.active {
				padding: 0 1rem;
			}
		}
	}
}

.dropdownIndicatorContainer {
	display: flex;
	padding-left: 1rem;
}

.mobileContainer {
	position: relative;
	background-color: $color-nav-heading-bg;
	position: absolute;
	top: 6rem;
	width: 100%;
	height: calc(100vh - 6rem);
	padding: 2rem 2rem 4.7rem 2rem;
	z-index: 99;
}

.closeButtonContainer {
	position: relative;
	display: flex;
	justify-content: flex-end;
	margin-bottom: 2.5rem;
}

.closeButton {
	display: flex;
}

.optionsHeader {
	font-weight: 700;
	font-size: 1.6rem;
	line-height: 2.4rem;
	color: $color-black;
	margin-bottom: 2.3rem;
	padding-left: 1.3rem;
}

.optionHighlight {
	font-weight: normal;
}

.popularPageOption {
	text-decoration: none;
	color: unset;

	&:hover {
		text-decoration: none;
		color: unset;
	}

	&:hover,
	&:focus {
		background-color: rgba(152, 200, 24, 0.5);
	}
}

.mobileSearchContainer {
	position: relative;
	margin-top: 4rem;
}