@import '../../../../app/colors';
@import '../../../../app/variables';

.navigationContainer {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-white;

    .navMenu {
        margin: 0;
        padding: 0;

        & > li {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            text-decoration: none;
            border-bottom: 1px solid rgba(85, 89, 94, 0.3);
            &:hover {
                a {
                    color: $color-hover-text;
                }
            }

            a {
                color: $color-text-link;
                display: block;
                font-size: 1.8rem;
                font-weight: 700;
                padding: 1.5rem 0.5rem 1.5rem 2rem;
                overflow: hidden;
                text-overflow: ellipsis;
                text-decoration: none;
                flex: 1;
            }

            .menuArrow {
                position: relative;
                display: block;
                border: none;
                width: 5rem;
                height: 5rem;
                background: none;
                outline: none;
                &:after {
                    position: absolute;
                    top: 50%;
                    right: 2rem;
                    transform: translateY(-50%);
                    font-family: icomoon, sans-serif;
                    content: '\E91F';
                    font-size: 1.2rem;
                    float: right;
                    color: #00a1ac;
                    pointer-events: none;
                }
            }
        }
    }
}
