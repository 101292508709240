@import "../../app/colors.scss";
@import "../../app/variables.scss";

.regionContainer {
	display: flex;
	padding: 2rem 1rem;
	background-color: $ticket-background;

	@media screen and (min-width: $desktop-breakpoint) {
		padding: 0;
		margin: 0 auto;
		margin-bottom: 7rem;
		max-width: 144rem;
	}

	.regionList {
		padding: 1.5rem 1.5rem 4rem 1.5rem;
		background-color: $color-white;
		width: 100%;
		@media screen and (min-width: $desktop-breakpoint) {
			position: relative;
			padding: 2.4rem 3rem 5rem 2.7rem;
		}
	}

	.regionMap {
		position: relative;
		display: none;
		@media screen and (min-width: $desktop-breakpoint) {
			display: inherit;
			width: 100%;
			min-width: 60%;
		}
	}
}

.regionItem {
	padding: 1.4rem 0 1.7rem;
	border-bottom: 1px solid rgb(229, 229, 229);
	color: rgb(45, 20, 110);
	font-size: 1.6rem;
	font-weight: bold;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	& > a {
		color: rgb(45, 20, 110);
		font-size: 1.6rem;
		font-weight: bold;
		text-decoration: none;
	}

	& > div {
		display: flex;
		flex-direction: column;
		justify-content: center;

		& > .arrow {
			font-size: 2rem;
			font-weight: bold;
			margin-left: 1rem;
			color: $color-icon-link;
			margin-bottom: 0.2rem;

			&:after {
				font-family: "icomoon", sans-serif;
				content: "\E91F";
				font-size: 1.4rem;
			}
		}
	}
}
