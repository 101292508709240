@import "../../app/colors.scss";
@import "../../app/variables.scss";

.zoneContainer {
	display: flex;
	padding: 2rem 1rem;
	background-color: $ticket-background;

	@media screen and (min-width: $desktop-breakpoint) {
		padding: 0;
		margin: 0 auto;
		margin-bottom: 7rem;
		max-width: 144rem;
	}

	.zoneList {
		background-color: $color-white;
		min-width: 40%;
		width: 100%;
		@media screen and (min-width: $desktop-breakpoint) {
			position: relative;
			min-height: 45.5rem;
		}
	}

	.zoneMap {
		position: relative;
		display: none;
		@media screen and (min-width: $desktop-breakpoint) {
			display: inherit;
			width: 100%;
			min-width: 60%;
		}
	}
}

.regionSection {
	display: flex;

	@media screen and (min-width: $desktop-breakpoint) {
		background-color: $color-white;
		padding: 3rem 3rem 3rem 2.7rem;
	}

	> *:first-child {
		width: 100%;
	}
	p {
		margin: 1.5rem 2rem;
	}
	@media screen and (min-width: $desktop-breakpoint) {
		p {
			margin: 1.5rem 0;
		}
	}
}

.regionSectionCollapsed {
	@media screen and (min-width: $desktop-breakpoint) {
		background-color: $color-ab-muted-3;
		> *:first-child {
			width: 40%;
		}
		&:nth-child(2) {
			position: static;
			padding: 0;
		}
	}
}

.zoneItem {
	font-size: 1.6rem;
	font-weight: bold;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 1rem;
	background-color: $color-white;

	@media screen and (min-width: $desktop-breakpoint) {
		border-bottom: 1px solid $zone-border;
		margin-bottom: 0;
	}

	& > .collapsedContainer {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		& > a {
			text-decoration: none;
			color: rgb(45, 20, 110);
			font-size: 1.6rem;
			font-weight: bold;
			padding: 1.7rem 2rem;

			@media screen and (min-width: $desktop-breakpoint) {
				padding: 1.7rem 0;
				color: rgb(45, 20, 110);
			}
		}

		& > .arrow {
			display: none;
			font-size: 2rem;
			font-weight: bold;
			margin-left: 1rem;
			color: $color-icon-link;
			margin-bottom: 0.2rem;

			@media screen and (min-width: $desktop-breakpoint) {
				display: block;
			}

			&:after {
				font-family: "icomoon", sans-serif;
				content: "\E91F";
				font-size: 1.4rem;
			}
		}
	}

	& .zoneDetails {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		border-top: 2px solid rgba(85, 89, 94, 0.3);
		padding: 1rem 2rem 3rem 2rem;
		font-weight: normal;

		@media screen and (min-width: $desktop-breakpoint) {
			display: none;
		}

		& .zonePlaces {
			flex-basis: 100%;
			font-size: 1.6rem;
		}

		button {
			margin-top: 1.8rem;
			padding: 0 3rem;
		}
	}

	.rect {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 5.6rem;
		width: 5.6rem;
		min-width: 5.6rem;
		background-color: $color-light-blue;
		color: $color-white;

		@media screen and (min-width: $desktop-breakpoint) {
			display: none;
		}

		&:before {
			font-family: "icomoon", sans-serif;
			content: "\E900";
			font-size: 3rem;
		}
		&.expandedPlus:before {
			margin-bottom: 0.7rem;
			font-size: 2rem;
			content: "\2014";
		}
	}
}
