@import "../../app/colors.scss";
@import "../../app/variables.scss";

.paymentConfirmationContainer {
	padding: 0;

	.ticketContainer {
		margin: 3.1rem 3rem 0;
		& .ticketInfo {
			margin-bottom: 1rem;
		}

		& .ticketDesktop {
			margin: 2rem;
		}
	}

	.bookingInfo {
		display: flex;
		justify-content: center;

		& .bookingWrap {
			display: flex;
			align-items: center;
			flex-direction: column;
			font-size: 1.6rem;
			padding: 3.1rem 2.7rem;
			text-align: center;

			.bookingTitle {
				font-size: 2.2rem;
				font-weight: bold;
				margin: 1rem 0 2rem;
			}

			.bookingId {
				margin-bottom: 2.2rem;
			}

			.actionButton {
				margin-top: 3.6rem;
				width: 100%;
			}

			.storeLinks {
				display: flex;
				justify-content: space-between;
				margin-top: 3.2rem;
			}
		}
	}
}

@media screen and (min-width: $desktop-breakpoint) {
	.paymentConfirmationContainer {
		display: flex;
		justify-content: space-around;
		background-color: $ticket-background;
		padding: 3rem 0 7rem;

		& .ticketContainer {
			flex-basis: 30%;
			order: 2;
			margin: 0;
			width: 30%;
		}

		& .bookingInfo {
			flex-basis: 40%;
			order: 1;
			background-color: $color-white;
			min-width: 58rem;

			.bookingWrap {
				padding-top: 4rem;
				max-width: 40rem;
			}
		}
	}
}
