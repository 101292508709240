@import "../../app/variables.scss";

.desktopJSTracker {
	width: 100%;
	padding-bottom: 2rem;
}

.desktopOnly {
	display: none;
	@media screen and (min-width: $desktop-breakpoint) {
		display: inherit;
		width: 100%;
	}
}
