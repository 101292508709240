@import '../../../app/colors';
@import '../animations';
@import '../../../app/variables';

.topBarDesktopContainer {
    display: none;
    z-index: 1000;
    @media screen and (min-width: $desktop-breakpoint) {
        display: flex;
        flex-direction: column;
    }

    .topLine {
        display: flex;
        justify-content: flex-end;
        padding: 0 4rem;
        height: 5rem;
        width: 100%;
        background-color: $color-ab-muted-3;
        .devider {
            position: relative;
            &::after {
                content: '';
                height: 100%;
                width: 0.1rem;
                background-color: $color-hint-text;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
        .topLineContent {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .accessLink,
            .locateLink,
            .accountLink {
                padding: 0 1rem;
                font-weight: bold;
                & > svg {
                    margin-left: 0.5rem;
                }
            }

            .basketLink {
                padding: 0 1rem;
                position: relative;
                .basketCounter {
                    right: 0;
                    top: -0.5rem;
                }
            }

            .accessLink {
                color: $color-body-copy;
            }

            .locateLink,
            .accountLink {
                white-space: nowrap;
                text-transform: uppercase;
                color: $color-text-link;
                display: flex;
                align-content: center;
            }
        }
    }
}

.navLink {
    text-decoration: none;
    color: inherit;
}

.topBarMobileContainer {
    @media screen and (min-width: $desktop-breakpoint) {
        display: none;
    }

    background: rgba(255, 255, 255, 0.95);
    width: 100%;
    max-width: $desktop-breakpoint;
    margin: 0 auto;
    overflow: hidden;

    &:focus {
        outline: none;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .navIcons {
        > div {
            display: flex;
        }
    }

    .searchIconContainer {
        position: relative;
        margin: 0 1rem;
    }

    .topBar {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 2rem;
        height: 6rem;
        border-top: 1px solid #aaa;
        border-bottom: 1px solid $color-icon-link;

        .logoContainer {
            cursor: pointer;
            appearance: none;
            background: none;
            border: 0;
            padding: 0 1rem;
            margin: 0;
        }

        .loginContainer {
            position: relative;
            padding-right: 0.5rem;
            margin: auto 0 auto 1rem;
        }

        .basketIconContainer {
            position: relative;
            margin: 0 1rem;
            outline: none;
            .basketCounter {
                right: -1rem;
                top: -0.5rem;
            }
        }
    }
}

header {
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
}

@media screen and (min-width: $desktop-breakpoint) {
    header {
        position: fixed;
    }
}
