@import "../../../../app/colors";
@import "../../../../app/variables";

.navigationContainer {
	min-height: 0;
	width: 100%;
	height: 8.7rem;
	background: $color-white;
	z-index: 100;
	display: flex;
	align-items: center;
}

@media screen and (max-width: $desktop-breakpoint) {
	.navigationContainer {
		position: absolute;
		top: 6rem;
		height: 0;

		.logoElement {
			display: none;
			width: 14.7rem;
			height: 4.4rem;
		}
	}
}

@media screen and (min-width: $desktop-breakpoint) {
	.navigationContainer {
		position: relative;

		& > ul {
			font-size: 1.6rem;
			font-weight: bold;
			color: $color-black;
			display: flex;
			align-items: center;
			width: 100%;
			margin: 0;
			padding: 0;

			& > li {
				display: block;
				padding: 1rem !important;

				& > a {
					color: $color-black;
					vertical-align: sub;
					height: 100%;
					white-space: nowrap;
				}
			}

			& > li:hover {
				& > a {
					color: $color-selected-active-state;
					text-decoration: none;
				}
			}
		}

		.subCategoriesContainer {
			background: $color-ab-muted-3 url("../../../../app/images/header-bg.png") no-repeat left top;
			background-size: cover;
			position: absolute;
			padding-bottom: 1rem;
			min-height: 365px;
			height: 100%;
			width: 100%;
			display: inline-table;
			top: 6rem;
			left: 0;
		}

		.firstSubCategory {
			display: block;
			margin-top: .5rem;
			padding-top: 3rem;
			padding-left: 22.7rem;

			li {
				cursor: pointer;
				display: block;
				max-width: 30rem;
				padding-top: 1rem;

				& > a {
					font-size: 1.8rem;
					font-weight: bold;
					color: $color-text-link;
					border-bottom: 1px solid rgba($color-hint-text, 0.3);
					width: 83%;
					overflow: hidden;
					text-overflow: ellipsis;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 1rem;

					&.hasChildren {
						position: relative;

						&:after {
							font-family: "icomoon", sans-serif;
							content: "\E91F";
							font-size: 1.2rem;
							color: $color-icon-link;
						}
					}
				}

				&:hover {
					& > a {
						text-decoration: none;
						color: $color-hover-text;

						&.hasChildren {
							&:after {
								color: $color-hover-text;
							}
						}
					}
				}

				&:last-child {
					& > a {
						border-bottom: none;
					}
				}
			}
		}

		.subCategoryBottom {
			height: 1px;
			width: 100%;
			opacity: 0;
			visibility: hidden;
		}

		.deepCategory {
			min-width: 5rem;
			position: absolute;
			float: left;
			z-index: 1000;
			display: flex;
			flex-direction: column;
			padding-top: 3rem;
			top: 0;
			left: 52rem;
			width: 30rem;
			height: 100%;

			li:last-of-type {
				& > a {
					border-bottom: none;
				}
			}
		}

		.hiddenSelectedSubCategory {
			display: none;
		}
	}
}
