@import "../../app/colors.scss";
.popupBackground {
	position: fixed;
	width: 100vw;
	height: 100vh;

	background-color: rgba(75, 75, 75, 0.7);
	z-index: 100;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.popupBackgroundInbox {
	border-radius: 0.3rem;
	background-color: #ffffff;
	box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.3);
	box-sizing: border-box;
	padding: 3rem;
	margin: 1.5rem;
	.radio {
		margin: 1rem 0;
	}
	.button {
		width: 100%;
	}
	.top {
		display: flex;
		justify-content: space-between;
		align-items: baseline;

		.text {
			font-size: 1.8rem;
			font-weight: bold;
			padding-right: 1.5rem;
		}
		.closeButton {
			height: 1.5rem;
			width: 1.5rem;
			margin-left: 2.5rem;
			&::before {
				height: 2.5rem;
				width: 2.5rem;
				font-family: "icomoon", sans-serif;
				content: "\E900";
				position: relative;
				// left: 12.5px;
				font-size: 2.5rem;
				color: $color-icon-link;
				transform: rotate(45deg);
				display: block;
				top: 3px;
				cursor: pointer;
			}
		}
	}
}
