@import '../../app/colors.scss';

.suggestedItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
    border-left: 0.6rem solid $ticket-blue;
    padding: 1.1rem 2.1rem 1.1rem 0;
    margin: 1rem 0;
    background-color: $color-white;
    user-select: none;

    &.secondary {
        margin: 0;
        box-shadow: none;
        border: none;
        border-bottom: 0.2rem solid rgba(85, 89, 94, 0.3);
        &:first-child {
            border-top: 0.2rem solid rgba(85, 89, 94, 0.3);
        }

        &:nth-last-child(2) {
            border-bottom: 0;
        }
    }

    &.noBorder {
        border-left: none;
    }

    .suggestedLeft {
        margin-left: 1.4rem;
        flex: 1;
    }

    & .title {
        display: flex;

        & > span:first-child {
            flex: 1;
        }
    }

    & .subTitle {
        display: flex;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .price {
        display: flex;
        align-items: center;
        margin-left: 0.5rem;
        font-weight: normal;
    }

    .alignIcon {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        height: inherit;

        & .ticketPicker {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & > span {
                padding: 0.5rem;
                color: rgb(53, 53, 53);
                font-size: 1.4rem;
                font-weight: bold;
            }
        }
    }

    .amount {
        font-size: 12px;
        margin-right: 3.5rem;
        margin-left: 2rem;
    }
}

.amountComputed {
    font-size: 16px;
    margin-left: 2rem;
}
