.ticketPicker {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & .ticketsAmount {
        margin: 0 1rem;
        font-size: 1.6rem;
        font-weight: 500;
    }

    & .ticketIcon {
        display: flex;
        align-items: center;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
    }
}
